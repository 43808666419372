import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import * as util from "../util";

// Styles
import "./HowToStream.css";

const HowToStream = ({ isMyChannel }) => {
  const basePath = util.getBasePath();

  let toRender = (
    <div className="aspect-169 bg-black">
      <div className="fl fl-a-center fl-j-center stream-offline pos-absolute full-width full-height">
        <h2>El stream ha finalizado</h2>
      </div>
    </div>
  );

  if (isMyChannel) {
    toRender = (
      <div className="how-to-stream">
        <div>
          <h2 className="pd-0">Cómo iniciar un evento en directo</h2>
          <hr className="mg-y-2" />
          <ol className="pd-0">
            <li className="pd-b-1">
              <span className="numberCircle mg-r-1">1</span>Consigue tu clave de
              transmisión en{" "}
              <Link to={`${basePath}settings`} className="link-to-settings">
                ajustes
              </Link>{" "}
              .
            </li>
            <li className="pd-b-1">
              <span className="numberCircle mg-r-1">2</span>Inserta la url y la
              clave en tu software de transmisión.
            </li>
            <li className="pd-b-1">
              <span className="numberCircle mg-r-1">3</span>Inicia el directo
              desde tu software de streaming.
            </li>
            <li className="pd-b-1">
              <span className="numberCircle mg-r-1">4</span>Refresca esta página
              para ver tu directo.
            </li>
          </ol>
        </div>
      </div>
    );
  }
  return <div className="full-width">{toRender}</div>;
};

HowToStream.propTypes = {
  isMyChannel: PropTypes.bool,
};

export default HowToStream;
