import React from "react";
import PropTypes from "prop-types";

const PasswordReq = ({ validPassword, className }) => {
  const validPasswordCls = validPassword ? "" : " color-destructive";
  const passwordCls = ` {${className}}`;

  return (
    <div
      className={`color-hint password-requirements${validPasswordCls}${passwordCls}`}
    >
      Tu contraseña debe cumplir con lo siguientes requisitos:
      <ul>
        <li>
          Al menos <b>8 carácteres</b>
        </li>
        <li>
          Incluir al menos <b>1 número</b>
        </li>
        <li>
          Incluir sl menos <b>1 caracter especial</b>
        </li>
        <li>
          Incluir <b>letras en mayúscula y en minúscula</b>
        </li>
      </ul>
    </div>
  );
};

PasswordReq.propTypes = {
  validPassword: PropTypes.bool,
  className: PropTypes.string,
};

export default PasswordReq;
